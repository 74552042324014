import { AxiosError } from 'axios'
import { makeAutoObservable, reaction } from 'mobx'
import { createContext, useContext } from 'react'
import { State } from 'src/api/vehicle/interfaces'
import { Optional } from 'src/common-interfaces/generic-interfaces'
import { SelectDiagram } from 'src/features/gfx/gfxSpecificConditions/DiagramSelector'
import { ErrorMessageBody } from 'src/features/partsCatalog/Selections/components/CheckboxList'
import miscellaneousVehicle, {
  isMiscellaneousVehicle,
} from 'src/features/search/Results/utils/miscellaneousVehicle'
import { random } from 'src/helpers/utils'
import {
  GfxConditionType,
  GfxRecordsData,
  GfxSpecificCondition,
  GfxSvgRecord,
} from '../../api/gfx/interfaces'

import {
  GaTrackOption,
  GoogleTagManager,
} from '../../config/analytics/GoogleTagManager'
import { StoreInstances } from '../StoreInstancesContainer'

import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import { LineType } from 'src/api/embedded/types/partsBasketSpec.ts'
import {
  CatalogIndexTracker,
  CatalogLookupType,
} from 'src/api/metrics/CatalogIndexTracker'
import { PartsBody, PartsRequest } from 'src/api/part/interface'
import { Config, IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { stockOptionsCases } from 'src/features/filterResults/components/stockOptionsCases'
import {
  IPartItemResponse,
  PartTypeItem,
} from 'src/features/partsCatalog/Selections/interfaces'
import { MultiPartInquiry } from 'src/features/search/AllProductsSearch/interfaces'
import { VehicleWidgetDisplayState } from 'src/features/search/VehicleSearch/store/VehicleWidgetStore'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import ListServiceProvider from 'src/services/ListServiceProvider'
import LostSaleServiceProvider from 'src/services/LostSaleServiceProvider'
import PartServiceProvider from 'src/services/PartServiceProvider'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import {
  VerifyStockedPartsRequest,
  VerifyStockedPartsResponse,
} from 'src/store/lists/interfaces'
import { ServiceIntervalsResponse } from '../../api/searchResults/interfaces'
import { buildAvailabilityRequest, fetchProduct } from '../cart/CartValidations'
import { makeNewCartVehicle, productsMatch } from '../cart/Utils'
import { PartInfoResponse } from '../cart/ValidationInterfaces'
import { IdValueGeneric, IdValuePair } from '../models/KeyValuePair'
import {
  LostSalesCost,
  LostSalesReasons,
  LostSalesReport,
} from '../models/LostSalesModel'
import { PartsCatalogSelections } from '../models/PartsCatalogModels'
import { PartType } from '../models/PartsCatalogStoreModels'
import {
  AvailableStores,
  PriceBreak,
  ProductLocationModel,
  ProductModel,
  QuantityPriceBreakHeader,
} from '../models/ProductModel'
import { Region } from '../models/Regions'
import {
  AstSearchResultsRequest,
  AstSelection,
  Breadcrumbs,
  Filters,
  LinesManufacturer,
  MileageOptions,
  MultiPartInquiryModel,
  OeParts,
  UpdatedPartInfoDetails,
  RequestParameterMileageOptions,
  RequestParameterRecommendedServices,
  SearchResultsModel,
  SearchResultsRequest,
  SearchResultsResponse,
  SortOrder,
  Direction,
  InterchangePartsBinRequest,
  InterchangeRequest,
} from '../models/SearchModels'
import { CartMode } from '../models/ShoppingCartModels'
import { Vehicle } from '../models/Vehicles'
import { PartsCatalog, PartsCatalogType } from '../partsCatalog/PartsCatalog'
import { CatalogTab } from '../uiState/UiStateStore'
import { UserAttributeKey } from '../user/interfaces'
import { CGPs, VehiclePreviousSearches } from '../vehicleHistory/interfaces'
import { getSortOptions } from 'src/features/search/Results/utils/GetSortOptions'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'
import { UuidType } from 'src/services/attributes/AttributesModel'
import {
  DiscardAndStartNewOrderActions,
  DuplicateVehicleActions,
} from 'src/ui-components/util/Interfaces'
import { MiscPreferencesKeys } from '../user/UserStore'
import { monitorUpdates } from './Helper'
import { getInterchangeParts } from 'src/helpers/getInterchangeParts'
import { parseBoolean } from 'src/ui-components/util/CommonFunctions'
export const SELECTED_VEHICLE_KEY = 'selected-vehicle'

export const AUTO_LIGHT_MEDIUM_TRUCK_ID = 111

const LOAD_PER_REQUEST = 10
const VEHICLE_SYSTEM = 'VEHICLE SYSTEM'

export const SearchStoreContext = createContext<SearchStore>(undefined)

const GFX_SKIP_SWITCH_LOOKUP_MODAL = 'gfx-skip-switch-lookup-modal'
const checkSkipModal = () =>
  sessionStorage.getItem(GFX_SKIP_SWITCH_LOOKUP_MODAL) === 'true'

export const STORAGE_SEARCH_STATE_KEY = 'search-state'

const partDomain = () =>
  StoreInstances?.userStore?.getUserAttribute(UserAttributeKey.partDomain)

const RETRY_SEARCH_LIMIT = 3

enum ClearSAYT {
  YES,
  NO,
}

export enum NonCachedSearchType {
  none,
  searchByNavigation,
  searchGfxByNavigation,
  searchByText,
  searchByInterchange,
  searchBySaytCategories,
  searchByAstNavigation,
  searchBySpecificParts,
  searchByMultiPartsInquiry,
}

export enum searchType {
  navigation = 'YMME',
  interchange = 'INTERCHANGE',
  partType = 'PART-TYPE',
  AllProducts = 'AST',
  partEnq = 'PART-INQUIRY',
}

export enum FilterGroupName {
  LINES = 'Lines',
  STOCK = 'Stock',
  BRANDS = 'Brands',
  PART_TYPE = 'Part Type',
}

export enum LineTypes {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  ALL_LINES = 'All Lines',
}

export enum StockOptions {
  ONLY_PRICED = 'Only Priced',
  ONLY_STOCKED = 'Only Stocked',
  SHOW_ALL = 'Show All',
}

export interface ProductCatalogInfo {
  category: string
  group: string
  partType: string
}

const showGfxError = (groupName: string): void => {
  StoreInstances.uiStore.openModalNotification({
    title: 'unavailableGroupGraphic',
    body: () => ErrorMessageBody(groupName),
    confirmText: 'yesIUnderstand',
    onConfirm: () => StoreInstances.uiStore.closeModalNotification(),
    onCancel: () => StoreInstances.uiStore.closeModalNotification(),
  })
}

export const DEFAULT_SORT_ORDER: SortOrder = [
  {
    property: 'Availability',
    label: 'Availability',
    dir: 'desc',
  },
  {
    property: 'Cost',
    label: 'Cost (Low-High)',
    dir: 'asc',
  },
]

interface IEnableResortItems {
  enable: boolean
  id: number
  index: number
}

export interface SaytRecommendation {
  text: string
  onClick: () => void
}

export class SearchStore {
  currentVehicle: Vehicle = {}

  states: Array<State> = []

  statesLoading = false

  diagrams: SearchResultsModel = undefined

  searchResults: SearchResultsModel = { filters: [] }

  enableResortItems?: IEnableResortItems = undefined

  engineOptions = []

  filters: Filters = []

  defaultStockFilterValue = ''

  sortOrder: SortOrder = DEFAULT_SORT_ORDER

  availableStores: AvailableStores = {}

  public nonAppProductModal = false

  public nonAppProductModalData = null

  gfxSvg: GfxSvgRecord

  gfxId: string = null

  gfxItem: IdValueGeneric<string, string> = { id: null, value: null }

  gfxData: GfxRecordsData

  gfxConditionsAnswers: Map<string, string>

  gfxAttributesAnswers: Map<string, string>

  gfxSelections: Array<string>

  gfxLastSelectedGroup: IdValueGeneric<string, string>

  gfxLookupData: string = undefined

  gfxLookupMade = false

  gfxIconIsLoading = false

  groupId: string = null

  isLoading = false

  loadingEngineOptions = false

  hasMoreResults = false

  lookupTitle: string = undefined

  oePartsLookupLoading = false

  oeParts: OeParts = []

  searchResultLimit = 3 // number of results to load per request

  searchError = ''

  showInterchangeDisclaimers = false

  breadCrumbs: Breadcrumbs[] = []

  showPartsNotFitVehicle = false

  quantityPriceBreakHeaderData: QuantityPriceBreakHeader = {}

  quantityPriceBreakData: PriceBreak[] = []

  showGfxSwitchLookupModal = false

  showVehicleTransition = false

  lastNonCachedSearch: NonCachedSearchType = NonCachedSearchType.none

  lastSaytCategories: PartsCatalogSelections

  lastMultiPartInquiry: MultiPartInquiry[] = []

  lookupItems: PartsBody[] = []

  /*
    Note:
      lastTextSearched
      lastInterchangeTextSearched
      We could have used a single variable but in the future we'll have to display a history
      of sayt searches, so the lastTextSearched will become a list
  */
  lastTextSearched: string

  lastInterchangeTextSearched: string

  // new select lines from manufacturers

  linesManufacturersData: LinesManufacturer[] = []

  linesManufacturersDataLoading = false

  selectedLinesManufacturers: string[] = []

  partDetailsLoading = false

  lostReportSaleProduct: ProductModel = {}

  lostSalesReasons: LostSalesReasons[] = []

  lostSalesQty = 0

  lostSaleCost: LostSalesCost

  currentFieldsSelections: Vehicle = {}

  lostSaleReportStatus = 0

  partDetailsError = ''

  showAllLinesForOrangeParts = false

  selectedServiceIntervalsType = null

  mileageOptions = []

  selectedMileageOptions = null

  recommendedServices: ServiceIntervalsResponse[] = []

  recommendedServicesLoading = false

  mileageOptionsLoading = false

  selectedCatalogDiagram: ProductModel = {}

  showAddAlltoCart = false

  multiPartInquiryResults: MultiPartInquiryModel[] = []

  cartSelectorModal = false

  retrySearchCount = 0

  catlogIndexForSearchByNavigation: CatalogLookupType = null

  searchResultPageScrollPosition = 0

  private _saytRecommendations: SaytRecommendation[]

  cgts: PartsCatalogSelections = []

  private _interchangeRequestData: InterchangeRequest = null

  public set saytRecommendations(recommendations: SaytRecommendation[]) {
    this._saytRecommendations = recommendations
  }

  public get saytRecommendations(): SaytRecommendation[] {
    return this._saytRecommendations
  }

  updatedPartInfoDetails: Array<UpdatedPartInfoDetails> = []

  constructor() {
    this.gfxAttributesAnswers = new Map<string, string>()
    this.gfxConditionsAnswers = new Map<string, string>()
    makeAutoObservable(this)
    this.restoreVehicleFromSession()
    this.loadBreadCrumbsFromStorage()
    this.currentFieldsSelections = this.currentVehicle
  }

  public setSelectedServiceIntervalsType = (
    selected: IdValueGeneric<string | number, string>
  ): void => {
    this.selectedServiceIntervalsType = selected
  }

  public setMileageOptions = (
    selected: IdValueGeneric<string | number, string>[]
  ): void => {
    this.mileageOptions = selected
  }

  public setDefaultStockFilterValue = (value: string): void => {
    this.defaultStockFilterValue = value
  }

  public setEnableResortItems = (): void => {
    this.enableResortItems = null
  }

  public setSelectedMileageOptions = (
    selected: IdValueGeneric<string | number, string>
  ): void => {
    this.selectedMileageOptions = selected
  }

  public setRecommendedServices = (
    recommendedServices: ServiceIntervalsResponse[]
  ): void => {
    this.recommendedServices = recommendedServices
  }

  setShowVehicleTransition = (state: boolean): void => {
    this.showVehicleTransition = state
  }

  setGfxIconIsLoading = (loading: boolean): void => {
    this.gfxIconIsLoading = loading
  }

  public setCurrentFieldsSelections = (vehicle: Vehicle): void => {
    this.currentFieldsSelections = vehicle
  }

  handleAddBreadCrumb = (breadcrumb: Breadcrumbs): void => {
    if (this.breadCrumbs.length === 0) {
      this.addBreadCrumb(breadcrumb)
    } else {
      this.cleanBreadCrumbs()
      this.addBreadCrumb(breadcrumb)
    }
  }

  addBreadCrumb = (breadcrumb: Breadcrumbs): void => {
    this.breadCrumbs.push(breadcrumb)
    const numBreadcrumbs = this.breadCrumbs.length
    if (numBreadcrumbs > 2) {
      this.breadCrumbs = this.breadCrumbs.slice(
        numBreadcrumbs - 2,
        numBreadcrumbs
      )
    }
    localStorage.setItem('breadCrumbs', JSON.stringify(this.breadCrumbs))
  }

  cleanBreadCrumbs = (): void => {
    this.breadCrumbs = []
  }

  updateBreadCrumbs = (breadcrumbs: Breadcrumbs[]): void => {
    this.breadCrumbs = breadcrumbs
  }

  removeBreadCrumbs = (): void => {
    this.breadCrumbs = []
    localStorage.removeItem('breadCrumbs')
  }

  private loadBreadCrumbsFromStorage = (): void => {
    if (localStorage.getItem('breadCrumbs')) {
      this.cleanBreadCrumbs()
      const storedBreadCrumbs = JSON.parse(localStorage.getItem('breadCrumbs'))

      this.updateBreadCrumbs(storedBreadCrumbs)
    }
  }

  updateCurrentVehicle = async (
    vehicle: Vehicle,
    preSelectedAction?: DuplicateVehicleActions
  ): Promise<boolean> => {
    if (
      EmbeddedCommunicationsManager.isAstPunchOut ||
      !Config?.isNotCartOnlyMode
    ) {
      return false
    }
    StoreInstances.cart.cleanEmptyCarts() // Should happen before addVehicleToCart
    const {
      addVehicleToCart,
      cleanAllCarts,
      areAllCartsEmpty,
      findFirstSimilarCartVehicle,
      getMode,
    } = StoreInstances.cart
    const { shouldMergeCarts: askTheUser, shouldDiscardAndStartNewCart } =
      StoreInstances.uiStore
    const { START_NEW, MERGE, CANCEL } = DuplicateVehicleActions

    const isSingleCartMode = getMode() === CartMode.SINGLE_VEHICLE
    const vehicleFound = findFirstSimilarCartVehicle(vehicle)
    const isMiscellaneous = isMiscellaneousVehicle(vehicleFound?.vehicle)

    const merge =
      vehicleFound && (isSingleCartMode || isMiscellaneous) ? MERGE : undefined
    const startNew = !vehicleFound ? START_NEW : undefined

    const actionToTake =
      preSelectedAction ??
      merge ??
      startNew ??
      (await askTheUser(vehicleFound?.vehicle))
    if (MERGE === actionToTake) {
      if (vehicle.engine.id !== miscellaneousVehicle.engine.id) {
        this.currentVehicle = vehicleFound.vehicle
        addVehicleToCart(this.currentVehicle)
      } else {
        this.currentVehicle = {}
        addVehicleToCart(vehicleFound.vehicle)
      }
      return true
    }

    if (actionToTake === START_NEW) {
      const shouldStartNewCart =
        isSingleCartMode &&
        (areAllCartsEmpty() ||
          (await shouldDiscardAndStartNewCart()) ===
            DiscardAndStartNewOrderActions.START_NEW)

      if (shouldStartNewCart) {
        cleanAllCarts(GaTrackOption.track)
      }

      const isUpdatingCart = !isSingleCartMode || shouldStartNewCart
      if (isUpdatingCart) {
        this.startNewCart(vehicle)
        return true
      }
      this.cancelUpdateCurrentVehicle()
    }
    if (CANCEL !== actionToTake) {
      this.cancelUpdateCurrentVehicle()
    }
    return false
  }

  private cancelUpdateCurrentVehicle = (): void => {
    StoreInstances.laborStore.lookup.resetStore()
    this.gfxConditionsAnswers = new Map<string, string>()
    this.gfxAttributesAnswers = new Map<string, string>()
    this.currentVehicle.specificConditions = []
  }

  private startNewCart = (vehicle: Vehicle) => {
    const { vehicles } = StoreInstances.cart
    const id = random()
    let newCartVehicle
    if (vehicle.engine.id !== miscellaneousVehicle.engine.id) {
      this.currentVehicle = { ...vehicle, id }
      newCartVehicle = makeNewCartVehicle({
        ...vehicle,
        id,
      })
    } else {
      this.currentVehicle = {}
      newCartVehicle = makeNewCartVehicle({
        ...vehicle,
      })
    }
    this.setCurrentFieldsSelections({ ...vehicle })
    StoreInstances.vehicleWidget.setIsDrawerOpened(false)
    StoreInstances.vehicleWidget.setDisplayState(VehicleWidgetDisplayState.view)
    vehicles.unshift(newCartVehicle)
  }

  deleteCurrentVehicle = (): void => {
    this.currentFieldsSelections = {}
    this.currentVehicle = {}
    StoreInstances.cart.cleanEmptyCarts()
    sessionStorage.setItem(STORAGE_SEARCH_STATE_KEY, '') // delete also the search state
    this.searchResults = {}
    this.diagrams = undefined
  }

  removeAllVehicles = (): void => {
    StoreInstances.cart.removeAllVehicles()

    this.currentFieldsSelections = {}
    StoreInstances.cart.setVehicle({})
    StoreInstances.cart.cleanAllCarts(GaTrackOption.track)
  }

  updateJsonSelections = (item: VehiclePreviousSearches): void => {
    const { categories, groups, parts } = item

    const SELECTED_PARTS_CATALOG_KEY = 'selected_parts_catalog_key'
    sessionStorage.setItem(
      SELECTED_PARTS_CATALOG_KEY,
      JSON.stringify({ categories, groups, parts })
    )
  }

  retrieveJSONSelections = (): CGPs => {
    const SELECTED_PARTS_CATALOG_KEY = 'selected_parts_catalog_key'
    const savedJSONCGPs =
      sessionStorage.getItem(SELECTED_PARTS_CATALOG_KEY) || '{}'
    return JSON.parse(savedJSONCGPs)
  }

  getStatesWithCountryId = async (): Promise<void> => {
    this.statesLoading = true
    const states = await UserDataServiceProvider.getStatesByCountry(
      StoreInstances.userStore.country.countryId
    )
    this.states = states
    this.statesLoading = false
  }

  persistCurrentVehicle = (): void => {
    if (this.currentVehicle?.engine) {
      const v = this.currentVehicle
      const { countryCode } = StoreInstances.userStore.country
      const regionId = Region[countryCode]
      GoogleTagManager.setVehicleDimensions(v, regionId)
    }
    sessionStorage.setItem(
      SELECTED_VEHICLE_KEY,
      JSON.stringify(this.currentVehicle)
    )
  }

  setGroupId = (groupId: string): void => {
    this.groupId = groupId
  }

  setGfxId = (id: string): void => {
    this.gfxId = id
  }

  setGfxItem = (item: IdValueGeneric<string, string>): void => {
    this.gfxItem = item
  }

  setLookupTitle = (title: string): void => {
    this.lookupTitle = title
  }

  setOePartsLookupLoading = (loading: boolean): void => {
    this.oePartsLookupLoading = loading
  }

  public searchByLookupItems = (parts: PartsBody[]): void => {
    this.lookupItems = parts
    this.searchBySpecificParts()
  }

  public searchBySpecificParts = (start = 0): void => {
    this.showInterchangeDisclaimers = false
    this.clearGfxSvg()
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqData = this.prepareBySpecificPartsRequestData(start)

    this.executeSearch(() => PartServiceProvider.byPartsApi(reqData), start > 0)
    this.lastNonCachedSearch = NonCachedSearchType.searchBySpecificParts
  }

  setSearchResults = (results: SearchResultsResponse): void => {
    const currentPartBin = this.searchResults?.partsBinId
    const updatedPartBin = !results.partsBinId
      ? currentPartBin
      : results.partsBinId

    this.searchResults = results
    this.searchResults.partsBinId = updatedPartBin
  }

  setShowPartsNotFitVehicleLabel = (show: boolean): void => {
    this.showPartsNotFitVehicle = show
  }

  get showPartsNotFitVehicleLabel(): boolean {
    return this.showPartsNotFitVehicle
  }

  setFilters = (filters: Filters): void => {
    this.filters = filters
    if (filters[0]?.items[0]?.valueOf() !== LineTypes.ALL_LINES) {
      this.clearResults(ClearSAYT.NO)
    }
  }

  setAvailableStores = (availableStores: AvailableStores): void => {
    this.availableStores = availableStores
  }

  setSortOrder = (sortOrder: SortOrder): void => {
    this.sortOrder = sortOrder

    if (this.lastNonCachedSearch === NonCachedSearchType.searchByInterchange) {
      StoreInstances.uiStore.showApplySortMessage = false
      this.applyInterchangeSearchSort()
      return
    }

    this.loadNextPage(true)
    this.setHasMoreResults(true)
  }

  saveSortOrderToCloud = (sortOrder: SortOrder): void => {
    const oldSortOrderString = StoreInstances?.userStore?.getUserAttribute(
      UserAttributeKey.displaySortOrder
    )
    let sortOrderList = oldSortOrderString.split(',')
    const sortByThenByList = sortOrder.map((order) => {
      let dir = order.dir === 'asc' ? '0' : '1'
      let property = order.property
      if (order.property === 'Recommended') {
        property = 'MCL'
        dir = '0'
      } else if (order.property === 'Availability') {
        dir = '0'
      } else if (order.property === 'Part Number') {
        property = 'PartNumber'
      } else if (order.property === 'Part Description') {
        property = 'Description'
      }
      sortOrderList = sortOrderList.filter((each) => !each.startsWith(property))
      return [property, dir].join('_')
    })
    sortOrderList.unshift(...sortByThenByList)
    const { userId } = StoreInstances.userStore.preferences
    AttributesServiceProvider.saveOrUpdateAttribute([
      {
        uuid: UuidType.DISPLAY_SORTORDER,
        domId: Number(userId),
        domType: 5,
        attrValue: sortOrderList.join(','),
      },
    ])
  }

  setQuantityPriceBreakHeaderData = (data: QuantityPriceBreakHeader): void => {
    this.quantityPriceBreakHeaderData = data
  }

  setQuantityPriceBreakData = (data: PriceBreak[]): void => {
    this.quantityPriceBreakData = data
  }

  setGfxData = (
    data: GfxRecordsData,
    selectedPartsGroup: IdValueGeneric<string, string>
  ): void => {
    this.gfxData = { ...data, selectedPartsGroup }
  }

  setShowGfxSwitchLookupModal(show: boolean): void {
    this.showGfxSwitchLookupModal = show
  }

  get gfxHasLoaded(): boolean {
    return this.gfxSvg.hasLoaded ?? false
  }

  setGfxHasLoaded = (hasLoaded: boolean): void => {
    this.gfxSvg.hasLoaded = hasLoaded
  }

  resetGfx = (): void => {
    if (this.gfxSvg) {
      this.gfxSvg.refreshKey = Math.random()
      this.setGfxHasLoaded(false)
    }

    this.gfxLookupMade = false
    this.gfxLookupData = undefined
    this.setLookupTitle(undefined)
    this.gfxLastSelectedGroup = undefined
    this.gfxSelections = undefined
  }

  clearGfxSvg = (): void => {
    if (!this.gfxSvg) {
      return
    }
    this.resetGfx()
    this.gfxSvg = undefined
  }

  forceClearGfx = async (): Promise<void> => {
    const selectedDiagram = SelectDiagram(this)
    try {
      const { selectedPartsGroup } = this.gfxData
      this.setGfxHasLoaded(false)
      this.clearGfxSvg()
      this.gfxSvg = await CatalogServiceProvider.getGFX({
        vehicle: this.currentVehicle,
        group: {
          id: selectedPartsGroup.id,
          value: selectedPartsGroup.value,
        },
        gfxAssetNbr: selectedDiagram,
        browserType: 1,
      })
      this.searchGfxByNavigation()
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  changeEngine = (engineSelection: IdValuePair<string>): void => {
    this.currentVehicle.engine = {
      id: parseInt(engineSelection.id, 10),
      value: engineSelection.value,
    }
    StoreInstances.uiStore.setShowEnginesModal(false)
    this.openGFX()
  }

  resetFiltersForNewSearch = (): void => {
    this.setFilters([])
    this.setSelectedLinesManufacturers([])
  }

  public answerGfxQuestion = (
    type: GfxConditionType,
    code: string,
    label: string
  ): void => {
    if (type === 'attribute') {
      this.gfxAttributesAnswers.set(code, label)
    } else if (type === 'condition') {
      this.gfxConditionsAnswers.set(code, label)
    }
  }

  public getGfxAnswer(type: GfxConditionType, code: string): Optional<string> {
    if (type === GfxConditionType.Attribute) {
      return this.gfxAttributesAnswers.get(code)
    }
    return this.gfxConditionsAnswers.get(code)
  }

  public handleCloseNonAppProductModal = (): void => {
    this.nonAppProductModal = false
  }

  public handleOpenNonAppProductModal(data: unknown): void {
    this.nonAppProductModalData = data
    this.nonAppProductModal = true
  }

  public openGFX = async (): Promise<void> => {
    try {
      this.setGfxIconIsLoading(true)
      this.setGfxId(this.gfxItem?.id)
      const gfxSpecCondResp =
        await CatalogServiceProvider.getGFXSpecificConditions({
          vehicle: this.currentVehicle,
          group: {
            id: parseInt(this.gfxItem.id, 10),
            value: this.gfxItem.value,
          },
        })

      if (gfxSpecCondResp === undefined) {
        StoreInstances.uiStore.displayErrorNotification(
          'GFX image is not found'
        )
        this.setGfxIconIsLoading(false)
        this.setGfxId(null)
        return
      }

      if (
        this.lastNonCachedSearch === NonCachedSearchType.searchByNavigation ||
        this.lastNonCachedSearch ===
          NonCachedSearchType.searchGfxByNavigation ||
        this.lastNonCachedSearch === NonCachedSearchType.none
      ) {
        /* Part types are a dependency for Specific Conditions,
           So the order below is really important */
        await StoreInstances.partsCatalog.selectSingleGroup(this.gfxItem.id)
        StoreInstances.partsCatalog.selectAllPartTypesInGroup(this.gfxItem.id)
      }
      this.setGfxData(gfxSpecCondResp, this.gfxItem)

      await StoreInstances.specificConditions.fetchSpecificConditionQuestions(
        this.currentVehicle,
        this.lastNonCachedSearch === NonCachedSearchType.searchByNavigation ||
          this.lastNonCachedSearch ===
            NonCachedSearchType.searchGfxByNavigation ||
          this.lastNonCachedSearch === NonCachedSearchType.none
          ? StoreInstances.partsCatalog.terminologies?.length > 0
            ? StoreInstances.partsCatalog.terminologies
            : StoreInstances.partsCatalog.searchedPartTypes
          : StoreInstances.specificConditions.terminologies,
        PartsCatalogType.PARTS_SEARCH,
        false /* error notification */
      )
      this.setGfxIconIsLoading(false)
      this.setGfxId(null)
      StoreInstances.uiStore.openGfxConditions()
    } catch (_error) {
      this.setGfxIconIsLoading(false)
      this.setGfxId(null)
      showGfxError(this.gfxItem.value)
    }
  }

  handleOnCancelGfxLookup = async (): Promise<void> => {
    const selectedDiagram = SelectDiagram(this)
    if (!this.gfxSelections && !this.gfxLastSelectedGroup) {
      this.resetGfx()
      return
    }
    const selectedPart = this.gfxSelections[0] /* From the last selection */
    const selectedGroup = this.gfxLastSelectedGroup
    this.setGfxHasLoaded(false)
    try {
      this.gfxSvg = await CatalogServiceProvider.getGFX({
        vehicle: this.currentVehicle,
        group: {
          id: selectedGroup.id,
          value: selectedGroup.value,
        },
        selectedGfxPartTypeIds: selectedPart,
        gfxAssetNbr: selectedDiagram,
        browserType: 1,
      })
      this.gfxSvg.refreshKey = Math.random()
    } catch (_e) {
      StoreInstances.uiStore.displayErrorNotification('Error refreshing GFX')
    }
  }

  handleOnConfirmGfxLookup = async (): Promise<void> => {
    const data = this.gfxLookupData
    this.showGfxSwitchLookupModal = false
    const currentData = data.split('darkred,')
    const groupAndDescDetails = currentData[1].split('|')[0]
    const groupAndDescArray = groupAndDescDetails.split('\u001D')
    const details = groupAndDescArray[0].split(',')
    const group = { id: details[1], value: details[1] }
    this.gfxLastSelectedGroup = group
    this.gfxSelections = details.slice(2, details.length)
    const selectedGfxPartTypeIds = details.slice(2, details.length)[0]
    const selectedDiagram = SelectDiagram(this)

    await StoreInstances.partsCatalog
      .fetchGfxTerminologies([group])
      .then(async (response) => {
        const partTypeItems = response as IPartItemResponse
        const cgts: PartsCatalogSelections = partTypeItems[0]?.items.map(
          (item: PartTypeItem) => {
            return {
              category: {
                id: 0,
                value: '',
              },
              group: {
                id: Number(group.id),
                value: group.value,
              },
              terminology: {
                id: Number(item.id),
                value: item.value,
              },
            }
          }
        )
        const reqData = this.prepareByNavigationRequestData(0, cgts)
        reqData.isGfxLookup =
          StoreInstances.userStore.getUserAttribute(
            UserAttributeKey.GFX_Enabled
          ) === 'true'
        await PartServiceProvider.getSearchResults(reqData).then((results) => {
          this.searchResults.partsBinId = results.partsBinId
        })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`cgts  --- ${JSON.stringify(e)}`)
      })

    try {
      this.setGfxHasLoaded(false)
      this.gfxSvg.refreshKey = Math.random()
      this.gfxSvg = await CatalogServiceProvider.getGFX({
        vehicle: this.currentVehicle,
        group: {
          id: group.id,
          value: group.value,
        },
        selectedGfxPartTypeIds,
        gfxAssetNbr: selectedDiagram,
        browserType: 1,
      })
    } catch (_e) {
      StoreInstances.uiStore.displayErrorNotification(
        'Error changing GFX group'
      )
    }
  }

  public handleGfxLookup = async (data: string): Promise<void> => {
    this.gfxLookupData = data
    if (SearchStore.isCurrentLookup(data)) {
      this.gfxLookupMade = true
      const currentData = data.split('darkgreen,')
      const groupAndDescDetails = currentData[1].split('|')[0]
      const groupAndDescArray = groupAndDescDetails.split('\u001D')
      const details = groupAndDescArray[0].split(',')
      this.gfxLastSelectedGroup = { id: details[1], value: details[1] }
      this.gfxSelections = details.slice(2, details.length)
      this.searchByGfxPartBin()
    } else if (SearchStore.isCurrentAllLinesLookup(data)) {
      /* const currentData = data.split('darkorange,')
      const groupAndDescDetails = currentData[1].split('|')[0]
      const groupAndDescArray = groupAndDescDetails.split('\u001D')
      const details = groupAndDescArray[0].split(',')
       console.log(
        `Lookup: ${details[0]}, Group :${
          details[1]
        }, DescriptionIds :${details.slice(2, details.length)}`
      ) */
      // @TODO: Need to handle this case, but I think it's a post-MVP task
      StoreInstances.uiStore.displaySuccessNotification(
        'Current ALL Lines lookup'
      )
      const currentData = data.split('darkorange,')
      const groupAndDescDetails = currentData[1].split('|')[0]
      const groupAndDescArray = groupAndDescDetails.split('\u001D')
      const details = groupAndDescArray[1].split(',')
      this.setShowAllLinesForOrangeParts(true)
      this.setOrangePartsSelection(details)
    } else if (SearchStore.isNewGroupLookup(data)) {
      const skipModal = checkSkipModal()
      if (skipModal) {
        await this.handleOnConfirmGfxLookup()
      } else {
        this.showGfxSwitchLookupModal = true
      }
    } else if (SearchStore.isOELookup(data)) {
      const currentData = data.split('darkblue,')
      const groupAndDescDetails = currentData[1].split('|')[0]
      const groupAndDescArray = groupAndDescDetails.split('\u001D')
      const details = groupAndDescArray[0].split(',')
      const descriptionIds = details.slice(2, details.length)
      this.gfxSelections = descriptionIds
      this.getOeParts(details.slice(1, details.length))
    }
  }

  public get hasAnsweredAllGfxQuestions(): boolean {
    const answeredConditions = this.hasAnsweredAllGfx(
      GfxConditionType.Condition,
      this.gfxData?.specificConditions
    )

    const answeredAttributes = this.hasAnsweredAllGfx(
      GfxConditionType.Attribute,
      this.gfxData?.attributes
    )

    return answeredConditions && answeredAttributes
  }

  private hasAnsweredAllGfx(
    type: GfxConditionType,
    questions: Array<GfxSpecificCondition> | undefined
  ): boolean {
    if (!questions) {
      return true
    }
    for (const question of questions) {
      const answer = this.getGfxAnswer(type, question.code)
      if (answer === undefined) {
        return false
      }
    }
    return true
  }

  getOeParts = (newGroups: string[]): void => {
    this.setOePartsLookupLoading(true)
    CatalogServiceProvider.getGfxOeParts({
      vehicle: this.currentVehicle,
      partTypeIds:
        this.gfxSelections.length > 1
          ? this.gfxSelections.join(',')
          : `${this.gfxSelections[0]},`,
      group: {
        id: newGroups[0],
        value: newGroups[0],
      },
      specificConditions: this.currentVehicle?.specificConditions,
    })
      .then((response) => {
        this.oeParts = response
      })
      .catch(() => {
        StoreInstances.uiStore.displayErrorNotification(
          'Unable to retrieve OE part data'
        )
      })
      .finally(() => {
        this.setOePartsLookupLoading(false)
      })
  }

  searchByNavigation = (start = 0, cgts?: PartsCatalogSelections): void => {
    this.showInterchangeDisclaimers = false
    this.clearGfxSvg()
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqData = this.prepareByNavigationRequestData(start, cgts)
    reqData.isGfxLookup =
      StoreInstances.userStore.getUserAttribute(
        UserAttributeKey.GFX_Enabled
      ) === 'true'
    this.executeSearch(
      () => PartServiceProvider.getSearchResults(reqData),
      start > 0
    )
    this.lastNonCachedSearch = NonCachedSearchType.searchByNavigation
  }

  searchByAstNavigation = (start = 0): void => {
    this.showInterchangeDisclaimers = false
    this.clearGfxSvg()
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqData = this.prepareAstPunchOutRequestData(start)
    this.executeSearch(
      () => PartServiceProvider.getAstPunchOutSearchResults(reqData),
      start > 0
    )
    this.lastNonCachedSearch = NonCachedSearchType.searchByAstNavigation
  }

  searchGfxByNavigation = (start = 0): void => {
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqData = this.prepareByNavigationRequestData(start)
    reqData.isGfxLookup =
      StoreInstances.userStore.getUserAttribute(
        UserAttributeKey.GFX_Enabled
      ) === 'true'

    if (
      reqData.cgts.length === 0 &&
      this.lastNonCachedSearch === NonCachedSearchType.searchBySaytCategories
    ) {
      reqData.cgts = this.cgts
    }

    if (
      reqData.cgts.length === 0 &&
      this.lastNonCachedSearch === NonCachedSearchType.searchByNavigation &&
      StoreInstances.partsCatalog.terminologies?.length === 0 &&
      StoreInstances.partsCatalog.searchedPartTypes?.length > 0
    ) {
      const partsCatalogSelections: PartsCatalogSelections =
        StoreInstances.partsCatalog.searchedPartTypes
          ?.filter((item) => item.isSelected)
          ?.map((item) => {
            return {
              category: {
                id: 999,
                value: '',
              },
              group: {
                id: item.groupId,
                value: item.groupValue,
              },
              terminology: {
                id: Number(item.id),
                value: item.value,
              },
            }
          })
      reqData.cgts = partsCatalogSelections
    }
    this.executeSearch(
      () => PartServiceProvider.getSearchResults(reqData),
      start > 0
    )
    this.lastNonCachedSearch = NonCachedSearchType.searchGfxByNavigation
  }

  private processSearchResults = async (
    results: SearchResultsResponse,
    append = false
  ): Promise<void> => {
    const { partsBinId } = this.searchResults
    const currentParts = this.searchResults?.parts ?? []

    if (!this.searchResults.isSnwFinished && results.isSnwFinished) {
      if (!this.enableResortItems?.enable && !this.enableResortItems?.id) {
        this.enableResortItems = {
          enable: true,
          id: results?.parts?.[0]?.lineNo,
          index: this.searchResults.parts?.length,
        }
      } else {
        this.enableResortItems = undefined
      }
    }
    const newParts = EmbeddedCommunicationsManager.isLocalInventoryEnabled
      ? await EmbeddedCommunicationsManager.getLocalInventory(results)
      : results.parts || []

    if (!newParts?.length) {
      this.retrySearchCount++
      if (this.retrySearchCount >= RETRY_SEARCH_LIMIT) {
        this.setHasMoreResults(false)
        this.retrySearchCount = 0
      }
      return
    }

    this.trackSearch(newParts)
    const totalResults = results.filteredCount ?? results.totalCount ?? 0
    if (totalResults > 10) {
      this.setHasMoreResults(true)
    }

    this.setSearchResults({
      partsBinId,
      ...results,
      parts: append ? [...currentParts, ...newParts] : [...newParts],
    })

    this.addStockInformationToParts(newParts)
  }

  private addStockInformationToParts = async (
    newParts: ProductModel[]
  ): Promise<void> => {
    const stockRequest: VerifyStockedPartsRequest[] = newParts
      .filter(
        ({ lineCode, partNumber, type }) =>
          lineCode && partNumber && type === LineType.part
      )
      .map(({ lineCode, partNumber }) => ({
        lineCode,
        partNumber,
      })) as VerifyStockedPartsResponse[]

    if (stockRequest.length < 1) return
    const newPartStockInformation =
      await ListServiceProvider.verifyStockedParts(stockRequest)

    if (!newPartStockInformation || newPartStockInformation.length < 1) return

    this.searchResults.parts?.forEach((part) => {
      const partFound = newPartStockInformation.find(
        ({ partNumber, lineCode }) =>
          part.partNumber.toUpperCase() === partNumber.toUpperCase() &&
          part.lineCode.toUpperCase() === lineCode.toUpperCase()
      )
      if (partFound) part.isStocked = partFound.isStocked
    })
  }

  private executeLastNonCachedSearch = (start: number): void => {
    switch (this.lastNonCachedSearch) {
      case NonCachedSearchType.searchByNavigation:
        this.searchByNavigation(start)
        break
      case NonCachedSearchType.searchGfxByNavigation:
        this.searchGfxByNavigation(start)
        break
      case NonCachedSearchType.searchByText:
        this.searchByText(this.lastTextSearched, start) // search term
        break
      case NonCachedSearchType.searchByInterchange:
        // getInterchangeParts(this.lastInterchangeTextSearched) // partnumber
        this.getInterchangeApiRequest(
          this._interchangeRequestData.interchangeText,
          this._interchangeRequestData.partTypes,
          this._interchangeRequestData.manufacturers,
          this._interchangeRequestData.partsBinId,
          parseBoolean(
            StoreInstances.userStore.miscPreferences?.[
              MiscPreferencesKeys.INTERCHANGE_PARTTYPES
            ]
          ),
          parseBoolean(
            StoreInstances.userStore.miscPreferences?.[
              MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
            ]
          )
        )
        break
      case NonCachedSearchType.searchBySaytCategories:
        this.searchBySaytCategories(this.lastSaytCategories, start)
        break
      case NonCachedSearchType.searchByAstNavigation:
        this.searchByAstNavigation(start)
        break
      case NonCachedSearchType.searchBySpecificParts:
        this.searchBySpecificParts(start)
        break
      case NonCachedSearchType.searchByMultiPartsInquiry:
        this.searchMultiPartInquiry(this.lastMultiPartInquiry)
        break
      default:
        // eslint-disable-next-line no-console
        console.log('No supported search')
    }
  }

  searchByPartBin = async (
    startOffset?: number,
    filterName?: string
  ): Promise<void> => {
    const start = startOffset ?? this.searchResults?.parts?.length ?? 0
    if (!this.searchResults || !this.searchResults.partsBinId) {
      return
    }
    try {
      const reqData = this.preparePartsBinRequestData(start)
      this.setIsLoading(true)
      let queryOverrides
      if (this.lastNonCachedSearch === NonCachedSearchType.searchByText) {
        queryOverrides = {
          catalogId: '7',
          covListId: partDomain(),
          partDomain: partDomain(),
        }
      }
      const results = await PartServiceProvider.getPartsBinResults(
        reqData.partsBinId,
        reqData,
        queryOverrides
      )
      // Reusing the initial filters if users selects otherthen lines filter(we are updateing filters only if user selects lines).
      if (results && filterName !== FilterGroupName.LINES) {
        results.filters = this.searchResults?.filters // Too risky to refactor this code for the release, for now just checking if results is not undefined.
      }
      await this.processSearchResults(results, start > 0)
      this.setIsLoading(false)
    } catch (e) {
      const statusCode = (e as AxiosError)?.response?.status
      if (statusCode === 404) this.executeLastNonCachedSearch(start)
      else this.setIsLoading(false)
    }
  }

  searchByGfxPartBin = (startOffset?: number): void => {
    const start = startOffset ?? this.searchResults?.parts?.length ?? 0
    this.showInterchangeDisclaimers = false
    if (!this.searchResults || !this.searchResults.partsBinId) {
      return
    }
    this.clearResults()

    try {
      const reqData = this.prepareGfxPartsBinRequestData()
      this.executeSearch(
        () =>
          PartServiceProvider.getGfxPartsBinResults(
            this.searchResults.partsBinId,
            reqData
          ),
        start > 0
      )
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
      if ((e as any)?.response?.status === 404)
        this.executeLastNonCachedSearch(start)
    }
  }

  searchByInterchange = (partNumber: string, start = 0): void => {
    this.clearGfxSearchForSAYT()
    this.showInterchangeDisclaimers = true
    CatalogIndexTracker.setIndex(CatalogLookupType.INTERCHANGE)
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }

    const reqData = this._interchangeRequestData

    const apiCall = async () => {
      const resp = await PartServiceProvider.getInterchangeResults(reqData)
      const partsBinId = resp.partsBinId
      if (partsBinId) this.monitorPriceForInterchangeSearch(partsBinId, reqData)
      return resp
    }

    this.executeSearch(apiCall, start > 0)
    this.cleanBreadCrumbs()
    this.addBreadCrumb({
      page: 'interchange',
      breadcumb: partNumber,
      url: () => getInterchangeParts(partNumber),
    })
    this.lastInterchangeTextSearched = partNumber
    this.lastNonCachedSearch = NonCachedSearchType.searchByInterchange
  }

  monitorPriceForInterchangeSearch = async (
    partsBinId: string,
    reqData: InterchangePartsBinRequest
  ): Promise<void> => {
    type R = SearchResultsResponse

    const newReqData = {
      ...reqData,
      clientUTCOffset: StoreInstances.userStore.utcOffset,
    }
    const target = (): Promise<R> =>
      PartServiceProvider.getPriceForInterchangeParts(partsBinId, newReqData)

    const passCheck = (resp: R) => resp.isSnwFinished

    /* 
    MPV3-5810 - temporary disabled code - Nov 13th 2024
    StoreInstances.uiStore.showMonitoringPricesMessage = true 
    */
    const resp = await monitorUpdates<R>({
      target,
      passCheck,
    })

    /* 
    MPV3-5810 - temporary disabled code - Nov 13th 2024
    StoreInstances.uiStore.showMonitoringPricesMessage = false
    StoreInstances.uiStore.showApplySortMessage = true 
    */

    this.isLoading = false // MPV3-5810 temporary line - remove once the API issues are resolved.
    this.searchResults.totalCount = resp.totalCount
    this.searchResults.filteredCount = resp.filteredCount
    this.searchResults.isSnwFinished = true
    this.searchResults.parts = [] // Force mobx to update all elements
    this.searchResults.parts = resp.parts
  }

  applyInterchangeSearchSort = async (): Promise<void> => {
    this.isLoading = true
    const partsBinId = this.searchResults?.partsBinId
    const sortOrder = this.sortOrder
    const stock = this.getStockFilter()

    const manufacturers =
      this.getAppliedFiltersByGroupName(FilterGroupName.BRANDS) ??
      this.getAvailableFiltersByGroupName(FilterGroupName.BRANDS)

    const partTypes =
      this.getAppliedFiltersByGroupName(FilterGroupName.PART_TYPE) ??
      this.getAvailableFiltersByGroupName(FilterGroupName.PART_TYPE)

    this._interchangeRequestData = {
      ...this._interchangeRequestData,
      manufacturers,
      partTypes,
      sortOrder,
      stock,
      clientUTCOffset: StoreInstances.userStore.utcOffset,
    }

    const reqData = this._interchangeRequestData

    const resp = await PartServiceProvider.getPriceForInterchangeParts(
      partsBinId,
      reqData
    )

    this.searchResults.isSnwFinished = true
    this.searchResults.parts = [] // Force mobx to update all elements
    this.searchResults.parts = resp.parts
    this.isLoading = false
  }

  private getStockFilter = (): string => {
    const getItems = (g: string) =>
      this.filters?.filter((f) => f.groupName === g).flatMap((f) => f.items)
    const stock = getItems('Stock')?.[0]

    if (stock) return stock

    return this.getDefaultStockFilterOption(
      StoreInstances?.userStore?.preferences?.display_filtering,
      StoreInstances?.userStore?.preferences?.display_userFiltering
    )
  }

  getAppliedFiltersByGroupName = (g: string): string[] => {
    const filters = this.filters
      ?.filter((f) => f.groupName === g)
      ?.flatMap((f) => f.items)

    return filters.length > 0 ? filters : null
  }

  getAvailableFiltersByGroupName = (g: string): string[] => {
    const filters = this.searchResults?.filters
      ?.filter((f) => f.groupName === g)
      ?.flatMap((f) => f.items)

    return filters.length > 0 ? filters : null
  }

  applyInterchangeSearchFilters = async (): Promise<void> => {
    StoreInstances.uiStore.showApplySortMessage = false
    this.isLoading = true
    const partsBinId = this.searchResults?.partsBinId
    const sortOrder = this.sortOrder

    const manufacturers =
      this.getAppliedFiltersByGroupName(FilterGroupName.BRANDS) ??
      this.getAvailableFiltersByGroupName(FilterGroupName.BRANDS)

    const partTypes =
      this.getAppliedFiltersByGroupName(FilterGroupName.PART_TYPE) ??
      this.getAvailableFiltersByGroupName(FilterGroupName.PART_TYPE)

    const stock = this.getStockFilter()

    const reqData = {
      manufacturers,
      partTypes,
      sortOrder,
      stock,
      clientUTCOffset: StoreInstances.userStore.utcOffset,
    }

    const resp = await PartServiceProvider.getPriceForInterchangeParts(
      partsBinId,
      reqData
    )

    this.searchResults.isSnwFinished = true
    this.searchResults.filteredCount = resp.filteredCount
    this.searchResults.parts = [] // Force mobx to update all elements
    this.searchResults.parts = resp.parts
    this.isLoading = false
  }

  clearGfxSearchForSAYT = (): void => {
    /* Clearing gfx search */
    this.gfxData = null
    this.gfxSvg = null
    if (this.gfxLookupMade) {
      this.resetGfx()
    }
  }

  searchByText = (text: string, start = 0): void => {
    this.clearGfxSearchForSAYT()
    this.showInterchangeDisclaimers = false
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqDataBase = this.prepareCommonRequestData(start)
    const reqData = {
      ...reqDataBase,
    }
    this.executeSearch(
      () =>
        PartServiceProvider.getSearchResultsByText(reqData, text, {
          catalogId: '7',
          covListId: partDomain(),
          partDomain: partDomain(),
        }),
      start > 0
    )
    GoogleTagManager.trackEvent('search', { search_term: text })
    this.lastTextSearched = text
    this.lastNonCachedSearch = NonCachedSearchType.searchByText
  }

  searchBySaytCategories = (
    categories: PartsCatalogSelections,
    start = 0
  ): void => {
    this.cgts = categories
    this.clearGfxSearchForSAYT()
    this.showInterchangeDisclaimers = false
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.resetFiltersForNewSearch()
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const common = this.prepareCommonRequestData(start)
    const req = {
      ...common,
      cgts: categories,
      vehicle: {
        ...this.currentVehicle,
        plate:
          this.currentVehicle.state !== undefined &&
          this.currentVehicle.state !== null
            ? `${this.currentVehicle.state} ${this.currentVehicle.plate}`
            : this.currentVehicle.plate === null
              ? ''
              : this.currentVehicle.plate,
      },
      isGfxLookup:
        StoreInstances.userStore.getUserAttribute(
          UserAttributeKey.GFX_Enabled
        ) === 'true',
    }
    this.executeSearch(
      () => PartServiceProvider.getSearchResults(req),
      start > 0
    )
    this.lastSaytCategories = categories
    this.lastNonCachedSearch = NonCachedSearchType.searchBySaytCategories
  }

  searchDiagramsByPartsBinId = async (partsBinId: string): Promise<void> => {
    try {
      this.diagrams = await PartServiceProvider.getDiagramsSearch(partsBinId)
    } catch (_e) {
      //
    }
  }

  private executeSearch = (
    apiCall: () => Promise<SearchResultsResponse>,
    appendResults: boolean
  ) => {
    this.setIsLoading(true)
    this.setHasMoreResults(true)
    StoreInstances.uiStore.showApplySortMessage = false
    apiCall()
      .then((results) => {
        if (results?.parts?.length === 0 && results?.filters?.length > 0) {
          this.searchResults.partsBinId = results.partsBinId
          this.searchResults.filters = results.filters
        }
        if (!appendResults) {
          //resetting qtyUpdatedPartDetails to initial value.
          this.updatedPartInfoDetails = []
          this.setSearchResultPageScrollPosition(0)
        }
        // when status code returns 204 and results are 0, resetting results and filters
        if (results.totalCount === undefined) {
          this.searchResults = {}
          this.setIsLoading(false) // MPV3-5810 - temporary code - Nov 13th 2024
        }
        return this.processSearchResults(results, appendResults)
      })
      .catch((e) => {
        this.searchResults = {}
        this.setSearchError(e.message)
      })
      .finally(() => {
        /* 
          MPV3-5810 - temporary code - Nov 13th 2024  
          Once the API is ready, run this.setIsLoading(false) regardles of the search type
        */
        if (
          this.lastNonCachedSearch !== NonCachedSearchType.searchByInterchange
        ) {
          this.setIsLoading(false)
        }
      })
  }

  loadNextPage = async (force = false): Promise<void> => {
    if (this.isLoading) return

    if (this.gfxLookupMade) {
      const reqData = this.prepareGfxPartsBinRequestData()

      const currentLength = this.searchResults?.parts?.length ?? 0
      const { totalCount, filteredCount } = this.searchResults
      const totalResults = filteredCount ?? totalCount ?? 0
      if (currentLength >= totalResults && !force) {
        this.setHasMoreResults(false)
        return // Results have been fully loaded, no need to make an API call
      }

      reqData.start = force ? 0 : currentLength
      if (this.sortOrder.length > 0) {
        reqData.sortOrder = this.sortOrder.map((item) => ({
          property: item.property,
          dir: item.dir,
        }))
      }
      let results
      try {
        this.setIsLoading(true)
        results = await PartServiceProvider.getGfxPartsBinResults(
          reqData.partsBinId,
          reqData
        )
      } finally {
        this.setIsLoading(false)
      }

      const currentParts = this.searchResults?.parts ?? []
      const newParts = results.parts ?? []
      this.setSearchResults({
        partsBinId: reqData.partsBinId, // Parts bin ID not currently included in the response from the parts bin, need to reuse from last req
        ...results,
        parts: force ? [...newParts] : [...currentParts, ...newParts],
      })
    } else {
      const currentLength = this.searchResults?.parts?.length ?? 0
      const { totalCount, filteredCount } = this.searchResults
      const totalResults = filteredCount ?? totalCount ?? 0
      if (currentLength >= totalResults && !force) {
        this.setHasMoreResults(false)
        return // Results have been fully loaded, no need to make an API call
      }
      const start = force ? 0 : currentLength
      await this.searchByPartBin(start)
    }
  }

  public setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading
  }

  public setHasMoreResults = (hasMore: boolean): void => {
    this.hasMoreResults = hasMore
  }

  private setSearchError = (searchError: string) => {
    this.searchError = searchError
  }

  public clearResults = (clearSAYT: ClearSAYT = ClearSAYT.YES): void => {
    if (!this.searchResults) return

    this.searchResults.parts = []
    this.searchResults.totalCount = 0
    this.searchResults.filteredCount = 0
    this.searchResults.isSnwFinished = false
    if (clearSAYT === ClearSAYT.YES) {
      this.saytRecommendations = []
    }
  }

  private clearPartsBinCache = async (partsBinId: string): Promise<void> => {
    if (!partsBinId) return

    try {
      this.searchResults.partsBinId = undefined
      await PartServiceProvider.clearPartsBinCache(partsBinId)
    } catch (_e) {
      //
    }
  }

  getEngineOptions = async (): Promise<void> => {
    try {
      this.loadingEngineOptions = true
      const engines = await CatalogServiceProvider.getEngineOptionsPlain(
        this.currentVehicle
      )
      this.engineOptions = engines
    } catch (_error) {
      // console.log(error)
    } finally {
      this.loadingEngineOptions = false
    }
  }

  private prepareByNavigationRequestData = (
    start: number,
    cgts?: PartsCatalogSelections
  ): SearchResultsRequest => {
    const formattedVehicle = {
      ...this.currentVehicle,
      initialized: undefined,
    }
    formattedVehicle.plate =
      formattedVehicle.state && formattedVehicle.plate
        ? `${formattedVehicle.state} ${formattedVehicle.plate}`
        : null
    delete formattedVehicle.state
    const commonData = this.prepareCommonRequestData(start)
    return {
      ...commonData,
      vehicle: formattedVehicle,
      cgts: cgts ? cgts : SearchStore.partsCatalogSelections(),
      isJobsLookup:
        StoreInstances.uiStore.selectedCatalogTab === CatalogTab.JOBS_CATALOG
          ? true
          : false,
    }
  }

  private prepareBySpecificPartsRequestData = (start: number): PartsRequest => {
    this.setIsLoading(true)

    const commonData = this.prepareCommonRequestData(start)
    return {
      ...commonData,
      parts: this.lookupItems,
    }
  }

  private prepareAstPunchOutRequestData = (
    start: number
  ): AstSearchResultsRequest => {
    const commonData = this.prepareCommonRequestData(start)
    return {
      ...commonData,
      astBrandTerminologyRequestItem:
        SearchStore.astPunchOutCatalogSelections(),
    }
  }

  private static astPunchOutCatalogSelections = (): AstSelection => {
    const { selectedPartTypes } = StoreInstances.astPunchOutStore.catalog
    const terminologies = selectedPartTypes?.map((sPT) => sPT.id)
    return { brand: '', terminologies } // We currently don't use the brand filter
  }

  private static partsCatalogSelections = (): PartsCatalogSelections => {
    const selectedSearch = StoreInstances.uiStore.selectedCatalogTab
    if (selectedSearch === CatalogTab.JOBS_CATALOG) {
      return StoreInstances.jobsCatalog.catalog.getCtgs()
    }
    return StoreInstances.partsCatalog.getCtgs()
  }

  private preparePartsBinRequestData = (start: number) => {
    return {
      partsBinId: this.searchResults.partsBinId,
      ...this.prepareCommonRequestData(start),
    }
  }

  private prepareGfxPartsBinRequestData = () => {
    const start = this.searchResults?.parts?.length ?? 0
    return {
      partsBinId: this.searchResults.partsBinId,
      gfxPartTypes: this.gfxSelections,
      ...this.prepareCommonRequestData(start),
    }
  }

  // TODO: refactor this function to make it easier to read.
  public getDefaultStockFilterOption = (
    displayFiltering: string,
    displayUserFiltering: string
  ): string => {
    const displayUserFilteringValue = (displayUserFilteringOption: string) => {
      if (displayUserFilteringOption === '1') {
        return StockOptions.ONLY_PRICED
      }

      if (displayUserFilteringOption === '2') {
        return StockOptions.ONLY_STOCKED
      }
      return StockOptions.SHOW_ALL
    }

    const stockOptions = stockOptionsCases(displayFiltering)

    const findDefaultOption = stockOptions.items.find(
      (item) => item === displayUserFilteringValue(displayUserFiltering)
    )
    if (findDefaultOption) {
      this.setDefaultStockFilterValue(
        displayUserFilteringValue(displayUserFiltering)
      )
      return displayUserFilteringValue(displayUserFiltering)
    }
    this.setDefaultStockFilterValue(stockOptions.items[0])
    return stockOptions?.items[0]
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public prepareCommonRequestData = (start: number) => {
    // Resetting the enableResortItems as user can come to search result page from YMME, Interchange, Search etc.
    if (!start) {
      this.setEnableResortItems()
    }
    const linesfilter = this.filters.find(
      (filter) => filter.groupName === FilterGroupName.LINES
    )
    const selectedLine = linesfilter && linesfilter.items[0]

    let lineLookupType = 1
    if (selectedLine === LineTypes.SECONDARY) {
      lineLookupType = 2
    }
    if (
      selectedLine === LineTypes.ALL_LINES &&
      this.selectedLinesManufacturers.length > 0
    ) {
      lineLookupType = 3
    }

    const defaultStockFilter = this.filters.find(
      (item) => item.groupName === FilterGroupName.STOCK
    )
      ? []
      : [
          {
            groupName: FilterGroupName.STOCK,
            items: [
              this.getDefaultStockFilterOption(
                StoreInstances?.userStore?.preferences?.display_filtering,
                StoreInstances?.userStore?.preferences?.display_userFiltering
              ),
            ],
          },
        ]
    return {
      start,
      limit: LOAD_PER_REQUEST,
      filters: [
        ...this.filters.filter(
          // Remove the lines group from the filters.
          (filter) => filter.groupName !== FilterGroupName.LINES
        ),
        ...defaultStockFilter,
      ],
      lineLookupType,
      manufacturers: this.selectedLinesManufacturers,
      sortOrder: this.sortOrder.map((item) => ({
        property: item.property,
        dir: item.dir,
      })),
      clientUTCOffset: StoreInstances.userStore.utcOffset,
    }
  }

  private restoreVehicleFromSession = () => {
    if (!IS_INTEGRATED_VERSION) {
      const savedVehicleJson =
        sessionStorage.getItem(SELECTED_VEHICLE_KEY) ?? '{}'
      this.currentVehicle = JSON.parse(savedVehicleJson)
    }
  }

  public restoreFiltersFromCache = (): void => {
    const sortOrderString = StoreInstances?.userStore?.getUserAttribute(
      UserAttributeKey.displaySortOrder
    )
    if (sortOrderString) {
      const sortOrderNestedList = sortOrderString.split(',').map((option) => {
        const innerOption = option.split('_')
        return [innerOption[0].trim(), innerOption[1].trim()]
      })
      const options = getSortOptions()
      const sortByThenBy: SortOrder = sortOrderNestedList
        .slice(0, 2)
        .map((list) => {
          let dir: Direction = list[1] === '0' ? 'asc' : 'desc'
          let property = list[0]
          if (property === 'MCL') {
            property = 'Recommended'
            dir = 'desc' // Because Recommended has no asc dir in the options above
          } else if (property === 'Availability') {
            dir = 'desc' // Because Availability has no asc dir in the options above
          } else if (property === 'PartNumber') {
            property = 'Part Number'
          } else if (property === 'Description') {
            property = 'Part Description'
          }
          const label = options.find(
            (option) => option.property === property && option.dir === dir
          ).label
          return { property, dir, label }
        })
      if (sortByThenBy.length > 0) this.setSortOrder(sortByThenBy)
    }
  }

  static isNewGroupLookup = (data: string): boolean =>
    data.indexOf('darkred') >= 0

  static isCurrentLookup = (data: string): boolean =>
    data.indexOf('darkgreen') >= 0

  static isCurrentAllLinesLookup = (data: string): boolean =>
    data.indexOf('darkorange') >= 0

  static isOELookup = (data: string): boolean => data.indexOf('darkblue') >= 0

  public saveSearchState = (): void => {
    sessionStorage.setItem(STORAGE_SEARCH_STATE_KEY, JSON.stringify(this))
  }

  public restoreSearchState = (): void => {
    const savedStateStr = sessionStorage.getItem(STORAGE_SEARCH_STATE_KEY)
    if (savedStateStr) {
      const restoredState: SearchStore = JSON.parse(savedStateStr)
      this.lastNonCachedSearch = restoredState.lastNonCachedSearch
      this.lastSaytCategories = restoredState.lastSaytCategories
      this.lastMultiPartInquiry = restoredState.lastMultiPartInquiry
      this.lastTextSearched = restoredState.lastTextSearched
      this.lastInterchangeTextSearched =
        restoredState.lastInterchangeTextSearched
      this._interchangeRequestData = restoredState._interchangeRequestData
      this.gfxSvg = restoredState.gfxSvg
      if (this.gfxSvg) {
        this.gfxSvg.hasLoaded = false
      }
      this.gfxData = restoredState.gfxData
      this.gfxLookupData = restoredState.gfxLookupData
      this.gfxSelections = restoredState.gfxSelections
      this.gfxAttributesAnswers = new Map(restoredState.gfxAttributesAnswers)
      this.gfxConditionsAnswers = new Map(restoredState.gfxConditionsAnswers)
      this.currentVehicle = restoredState.currentVehicle
      if (window.location.pathname === '/searchResults')
        this.executeLastNonCachedSearch(0)
    }
  }

  public initReactions = async (): Promise<void> => {
    await StoreInstances.userStore.loadingUser
    this.restoreSearchState()
    /** Save any time the parts-bin updates */
    reaction(
      () => StoreInstances.searchStore.searchResults.partsBinId,
      () => {
        this.saveSearchState()
      }
    )
    reaction(
      () => this.currentVehicle,
      () => this.persistCurrentVehicle()
    )
    reaction(
      () => this.lastNonCachedSearch,
      () => this.handleShowAddAllToCart()
    )
  }

  private trackSearch(parts: Array<ProductModel>) {
    const allParts = parts?.reduce((acc, p) => {
      const partsToAdd = [p]
      if (p.alternateParts) partsToAdd.push(...p.alternateParts)
      if (p.replacementParts) partsToAdd.push(...p.replacementParts)
      return [...acc, ...partsToAdd]
    }, [] as ProductModel[])

    const searchTerm = this.gaLastSearchTerm()
    const vehicleSystem = this.gfxAttributesAnswers.get(VEHICLE_SYSTEM)
    GoogleTagManager.trackSearchResults(
      allParts,
      this.lastNonCachedSearch,
      this.gaProductCatalogInfo,
      searchTerm,
      vehicleSystem
    )
  }

  public gaLastSearchTerm = (): string => {
    if (
      this.lastNonCachedSearch === NonCachedSearchType.searchByText ||
      this.lastNonCachedSearch === NonCachedSearchType.searchBySaytCategories
    )
      return this.lastTextSearched

    if (this.lastNonCachedSearch === NonCachedSearchType.searchByInterchange)
      return this.lastInterchangeTextSearched

    return ''
  }

  // This method is to feed google analytics, don't use for something else
  public gaProductCatalogInfo = (product: ProductModel): ProductCatalogInfo => {
    const { selectedCategories, selectedGroups, selectedPartTypes } =
      this.lastPartsCatalogInstance()

    // A group can belong to multiple categories, but just getting the first one is fine for now
    const category = selectedCategories.find((c) =>
      c.children.find((g) => g.id === product.group?.id)
    )

    const group = selectedGroups.find((g) => g.id === product.group?.id)

    const partType = selectedPartTypes.find(
      (pt) => pt.id.toString() === product.allianceterminologyId.toString()
    )

    return {
      category: category?.value,
      group: group?.value,
      partType: partType?.value,
    }
  }

  // new select lines from manufacturers

  getLinesManufacturersData = async (): Promise<void> => {
    this.linesManufacturersDataLoading = true
    try {
      // @TODO: Find out why this is sometimes returning a 500 status
      // Fails when doing a interchange search
      this.linesManufacturersData =
        await PartServiceProvider.getLinesManufacturers(
          this.searchResults.partsBinId
        )
    } catch (_e) {
      this.linesManufacturersData = []
    }
    this.linesManufacturersDataLoading = false
  }

  setSelectedLinesManufacturers = (selectedOptions: string[]): void => {
    this.selectedLinesManufacturers = selectedOptions
  }

  setpartDetailsLoading = (loading: boolean): void => {
    this.partDetailsLoading = loading
  }

  // set lost report sale product
  setLostReportSaleProduct = (lostReportSaleProduct: ProductModel): void => {
    this.lostReportSaleProduct = lostReportSaleProduct
  }

  getLostSalesReasons = async (): Promise<void> => {
    this.lostSalesReasons = await LostSaleServiceProvider.getLostSalesReasons()
  }

  sendLostSaleReport = async (reqData: LostSalesReport): Promise<void> => {
    this.lostSaleReportStatus =
      await LostSaleServiceProvider.sendLostSaleReport(reqData)
  }

  setLostSaleReportStatus = (value: number): void => {
    this.lostSaleReportStatus = value
  }

  setLostSalesQty = (lostSalesQty: number): void => {
    this.lostSalesQty = lostSalesQty
  }

  setLostSaleCost = (lostSaleCost: LostSalesCost): void => {
    this.lostSaleCost = lostSaleCost
  }

  setPartDetailsError = (error: string): void => {
    this.partDetailsError = error
  }

  setSelectedCatalogDiagram = (selectedCatalogDiagram: ProductModel): void => {
    this.selectedCatalogDiagram = selectedCatalogDiagram
  }

  /* TODO: implement proper hash */
  // Vehicles in cartVehicle have vehicleID but vehicles in OrderResponseDetail don't have vehicleID.
  // Hence we use orderIndex when we generate getVehicleHash from orderConfirmation Page.
  // We use vehcile.id when we generate getVehicleHash from cartPage.
  getVehicleHash = (vehicle: Vehicle, orderIndex?: number): string => {
    return (
      vehicle.year?.value +
      vehicle.make?.value +
      vehicle.model?.value +
      (vehicle.id ? vehicle.id : orderIndex)
    )
  }

  setShowAllLinesForOrangeParts = (value: boolean): void => {
    this.showAllLinesForOrangeParts = value
  }

  // this will fetch updated price information for the products when we change qty in the qty selector of the results page.
  findProductAvaliabilty = async (
    qty: number,
    product: ProductModel,
    activeLocation: ProductLocationModel
  ): Promise<PartInfoResponse> => {
    const avaliabiltyRequestData = buildAvailabilityRequest(
      product,
      qty,
      activeLocation
    )
    const updatedPartInfo = await fetchProduct({
      parts: [avaliabiltyRequestData],
    })

    return updatedPartInfo
  }

  setOrangePartsSelection = (selectedOrangeParts: string[]): void => {
    const temp = this.linesManufacturersData
    this.setSelectedLinesManufacturers(
      temp
        .filter((manufacturerData) =>
          selectedOrangeParts.includes(manufacturerData.catalogLineCode)
        )
        .map((manufacturerData) => manufacturerData.name)
    )
  }

  getMileageOptions = async (
    requestBody: Vehicle
  ): Promise<MileageOptions[]> => {
    this.mileageOptionsLoading = true
    const requestParameter: RequestParameterMileageOptions = {
      intervalType: 0,
      severeCode: !this.selectedServiceIntervalsType
        ? 'Normal'
        : this.selectedServiceIntervalsType.value,
    }
    const resp = await CatalogServiceProvider.getServiceIntervals(
      requestParameter,
      requestBody
    )
    this.setMileageOptions(
      resp?.map((item) => ({
        id: item.mileageNum,
        value: item.mileageText,
      }))
    )
    this.mileageOptionsLoading = false
    return resp
  }

  getRecommendedServicesTable = async (
    requestBody: Vehicle
  ): Promise<ServiceIntervalsResponse[]> => {
    this.recommendedServicesLoading = true
    const requestParameter: RequestParameterRecommendedServices = {
      intervalType: 0,
      severeCode: !this.selectedServiceIntervalsType
        ? 'Normal'
        : this.selectedServiceIntervalsType.value,
      mileage: this.selectedMileageOptions?.id,
    }
    const resp = await CatalogServiceProvider.getRecommendedServices(
      requestParameter,
      requestBody
    )
    this.setRecommendedServices(resp)
    this.recommendedServicesLoading = false
    return resp
  }

  public searchMultiPartInquiry = (
    multiPartsList: MultiPartInquiry[]
  ): void => {
    this.showInterchangeDisclaimers = false
    this.clearGfxSvg()
    const previousPartsBinId = this.searchResults?.partsBinId
    this.resetFiltersForNewSearch()
    this.clearResults()
    this.clearPartsBinCache(previousPartsBinId)

    this.resetMultiPartInquiryResults()

    const multiPartInquiry = multiPartsList.map((item) => {
      return {
        partNumber: item.partNumber.toString(),
        lineCode: item.lineCode,
        qty: item.stockQty,
        description: item.comment,
      }
    })

    const commonData = this.prepareCommonRequestData(0)
    const reqData = {
      ...commonData,
      parts: multiPartInquiry,
    }
    this.executeSearch(
      () => PartServiceProvider.byPartsApi(reqData, true),
      false
    )
    this.lastMultiPartInquiry = multiPartsList
    this.lastNonCachedSearch = NonCachedSearchType.searchByMultiPartsInquiry
    this.setShowAddAlltoCart(true)
  }

  setShowAddAlltoCart = (value: boolean): void => {
    this.showAddAlltoCart = value
  }

  setMultiPartInquiryResults = (
    part: ProductModel,
    activeLocation: ProductLocationModel,
    qty: number
  ): void => {
    this.multiPartInquiryResults.push({
      part,
      activeLocation,
      qty,
    })
  }

  updateMultiPartInquiryResults = (
    part: ProductModel,
    activeLocation: ProductLocationModel,
    qty: number
  ): void => {
    const objIndex = this.multiPartInquiryResults.findIndex(
      (obj) => obj.part.lineNo === part.lineNo
    )
    this.multiPartInquiryResults[objIndex].activeLocation = activeLocation
    this.multiPartInquiryResults[objIndex].qty = qty
  }

  public findSelectedPartTypeById(partTypeId: string): PartType {
    return this.lastPartsCatalogInstance().findSelectedPartTypeById(partTypeId)
  }

  public lastPartsCatalogInstance(): PartsCatalog {
    if (this.lastNonCachedSearch === NonCachedSearchType.searchByAstNavigation)
      return StoreInstances.astPunchOutStore.catalog
    return StoreInstances.partsCatalog
  }

  resetMultiPartInquiryResults = (): void => {
    this.multiPartInquiryResults = []
  }

  public closeCartSelectorModal = (): void => {
    this.cartSelectorModal = false
  }

  public setCartSelectorModal = (value: boolean): void => {
    this.cartSelectorModal = value
  }

  handleShowAddAllToCart = (): void => {
    const shouldShow =
      this.lastNonCachedSearch === NonCachedSearchType.searchByMultiPartsInquiry
    this.setShowAddAlltoCart(shouldShow)
  }

  /* This method should be only used only when we select CGPs and do a byNavigation kind of search. Not for interchange and All products search */
  public updateCatalogIndex = (): void => {
    if (this.currentVehicle.plate) {
      CatalogIndexTracker.setIndex(CatalogLookupType.PLATE)
    } else if (this.currentVehicle.vin) {
      CatalogIndexTracker.setIndex(CatalogLookupType.VIN)
    } else {
      // Lookup type is YMME.
      // Using AST_NO_MATCH as it has the same value as YMME.
      CatalogIndexTracker.setIndex(CatalogLookupType.AST_NO_MATCH)
    }
  }

  filterAstPartSearch = (text: string, start = 0): void => {
    this.clearGfxSearchForSAYT()
    this.showInterchangeDisclaimers = false
    if (start === 0) {
      const previousPartsBinId = this.searchResults?.partsBinId
      this.clearResults()
      this.clearPartsBinCache(previousPartsBinId)
    }
    const reqDataBase = this.prepareCommonRequestData(start)
    const reqData = {
      ...reqDataBase,
    }
    this.executeSearch(
      () =>
        PartServiceProvider.getSearchResultsByText(reqData, text, {
          catalogId: '7',
          covListId: partDomain(),
          partDomain: partDomain(),
        }),
      start > 0
    )
    GoogleTagManager.trackEvent('search', { search_term: text })
    this.lastTextSearched = text
    this.lastNonCachedSearch = NonCachedSearchType.searchByText
  }

  public setUpdatedPartInfoDetails = (
    updatedDetails: UpdatedPartInfoDetails
  ): void => {
    const hasQtyUpdatedPart = this.updatedPartInfoDetails.findIndex((item) =>
      productsMatch(updatedDetails.product, item.product)
    )
    if (hasQtyUpdatedPart > -1) {
      this.updatedPartInfoDetails[hasQtyUpdatedPart] = updatedDetails
    } else {
      this.updatedPartInfoDetails.push(updatedDetails)
    }
  }

  public getUpdatedPartInfoDetails = (
    product: ProductModel
  ): UpdatedPartInfoDetails => {
    return this.updatedPartInfoDetails?.find((item) =>
      productsMatch(product, item.product)
    )
  }

  public setSearchResultPageScrollPosition = (position: number): void => {
    this.searchResultPageScrollPosition = position
  }

  public getInterchangeApiRequest = (
    interchangeText: string,
    partTypes: string[],
    manufacturers: string[],
    partsBinId: string,
    isPartTypeSkiped: boolean,
    isManufacturerSkiped: boolean
  ): unknown => {
    StoreInstances.userStore.updateMiscPreferences({
      [MiscPreferencesKeys.INTERCHANGE_PARTTYPES]: `${isPartTypeSkiped}`,
      [MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS]: `${isManufacturerSkiped}`,
    })

    /* 
      Due to an API limitation, the interchange search (Non-cached search only)
      doesn't support sorting by Cost, Availability or List
    */
    /* 
    MPV3-5810 - temporary disabled code - Nov 13th 2024
    const notSupported = (i) =>
      i.label?.includes('Cost') ||
      i.label?.includes('Availability') ||
      i.label?.includes('List')

    const applySort = this.sortOrder?.filter(notSupported)?.length <= 0

    const sortOrder = applySort ? this.sortOrder : null */

    const sortOrder = this.sortOrder
    const stock = this.getStockFilter()

    const ApiRequestData = {
      interchangeText,
      partTypes,
      manufacturers,
      partsBinId,
      sortOrder,
      stock,
    }

    this._interchangeRequestData = ApiRequestData

    this.searchByInterchange(interchangeText)

    return ApiRequestData
  }
}

export const useSearchStore = (): SearchStore => {
  const searchStore = useContext(SearchStoreContext)
  if (!searchStore) {
    throw new Error(
      'No SearchStoreContext.Provider found when calling useSearchStore.'
    )
  }
  return searchStore
}
